/* eslint-disable */
/*! CSS Used from: Embedded */
/*! CSS Used fontfaces */
@font-face {
  font-family: rsuite-icon-font;
  src: url(https://cdn.jsdelivr.net/npm/rsuite/dist/styles/fonts/rsuite-icon-font.ttf)
      format("truetype"),
    url(https://cdn.jsdelivr.net/npm/rsuite/dist/styles/fonts/rsuite-icon-font.woff)
      format("woff");
  font-weight: 400;
  font-style: normal;
}

*,
:after,
:before {
  box-sizing: border-box;
}
a {
  background-color: transparent;
}
a:active,
a:hover {
  outline: 0;
}
a {
  color: #1675e0;
  text-decoration: none;
}

a:focus,
a:hover {
  color: #0a5dc2;
}
a:active,
a:focus,
a:hover {
  text-decoration: underline;
}
a:active {
  color: #f1c500;
}
a:focus {
  outline: 0;
} /*
.rs-btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  outline: 0 !important;
  white-space: nowrap;
  border: none;
  -webkit-user-select: none;
  user-select: none;
  padding: 8px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  border-radius: 6px;
}
.rs-btn:focus,
.rs-btn:hover {
  color: #575757;
  text-decoration: none;
}
.rs-btn:active {
  outline: 0;
  background-image: none;
}
a.rs-btn {
  text-decoration: none;
}
.rs-btn-default {
  overflow: hidden;
  position: relative;
} */
@media not all and (min-resolution: 0.001dpcm) {
  .rs-btn-default {
    -webkit-mask-image: -webkit-radial-gradient(#fff, #000);
  }
}
/* .rs-btn-default {
  color: #575757;
  background: #0b0b74;
}
.rs-btn-default {
  transition: color 0.2s linear, background-color 0.3s linear;
} */
/* .rs-btn-default:focus,
.rs-btn-default:hover {
  color: #575757;
  background-color: #eaeae5;
}
.rs-btn-default:active {
  color: #575757;
  background-color: #d9d9d9;
  background-image: none;
} */
/*  .rs-btn-default:active:focus,
.rs-btn-default:active:hover {
  color: #575757;
  background-color: #d9d9d9;

}  */

/* .rs-picker-daterange.rs-picker-default .rs-picker-toggle-caret {
  top: 7px;

} */

.rs-picker-daterange .rs-picker-toggle-caret:before {
  content: "\f073" !important;
  line-height: 17px;
}
.rs-picker-toggle-wrapper {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
}
.rs-picker-toggle .rs-picker-toggle-placeholder {
  color: #ffffff;
}

.rs-picker-daterange-panel-show-one-calendar {
  position: fixed;
  width: 80%;
  left: 50%;
  transform: translate(-50%, 0);
  background: rgb(1, 20, 53);
  background: linear-gradient(
    0deg,
    rgb(1, 7, 17, 0.98) 0%,
    rgb(6, 27, 63, 0.98) 100%
  );
  margin-top: 10px;
  border-radius: 10px;
  border: 2px solid #1d1d5a60;
  max-width: 300px;
}
.rs-picker-toggle {
  font-weight: 400;
  text-align: center;

  cursor: pointer;
  outline: 0 !important;
  border: none;
  -webkit-user-select: none;
  user-select: none;

  font-size: 13px;
  border-radius: 20px;
}
.rs-picker-toggle:focus,
.rs-picker-toggle:hover {
  color: #c9c9c9;
  text-decoration: none;
}
.rs-picker-toggle:active {
  outline: 0;
  background-image: none;
}
.rs-picker-default .rs-picker-toggle {
  padding: 7px 11px;
}
.rs-picker-default .rs-btn,
.rs-picker-default .rs-picker-toggle {
  transition: border-color 0.3s ease-in-out;
}
.rs-picker-default:not(.rs-picker-disabled) .rs-btn:hover,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover {
  border-color: #1675e0;
}
.rs-picker-toggle-wrapper .rs-picker-toggle.rs-btn {
  padding-right: 32px;
}
.rs-picker-toggle.rs-btn .rs-picker-toggle-caret {
  top: 8px;
}
.rs-picker-default .rs-picker-toggle.rs-btn .rs-picker-toggle-caret {
  top: 7px;
}
.rs-picker-toggle.rs-btn .rs-picker-toggle-caret {
  right: 12px;
}
.rs-picker-daterange .rs-picker-toggle.rs-btn {
  padding-right: 36px;
}
.rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-caret {
  top: 8px;
}
.rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-caret {
  right: 12px;
}
.rs-picker-default .rs-picker-toggle.rs-btn {
  /* padding-top: 7px;
  padding-bottom: 7px; */
}
.rs-picker-toggle {
  /*   text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%; */
}
.rs-picker-default .rs-picker-toggle {
  position: relative;
  z-index: 5;
  padding: 30px;
  display: flex;

  align-items: center;
  vertical-align: center;
  outline: none;
  cursor: pointer;
  color: #ffffff;
  border: 2px solid #d99f01;
  box-shadow: 0 0 18px 0 #ffaa00;
  height: 60px;
}
.rs-picker-toggle-clean {
  display: none;
}
.rs-picker-toggle-caret {
  display: none;
  /*   margin-left: 2px;
  top: 8px;
  right: 12px;
  font-weight: 100;
  color: #d8d8d8; */
}
.rs-picker-toggle-caret:before {
  font-family: rsuite-icon-font !important;
}
.rs-picker-placement-bottom-start .rs-picker-toggle-caret:before,
.rs-picker-toggle-caret:before {
  content: "\ea08";
}
.rs-picker-default .rs-btn,
.rs-picker-default .rs-picker-toggle {
  background: rgba(56, 129, 255, 0.25) !important;
}
/*! CSS Used from: Embedded */
.calDesign {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;

  margin-top: 20px;
}
/*! CSS Used fontfaces */
@font-face {
  font-family: rsuite-icon-font;
  src: url(https://cdn.jsdelivr.net/npm/rsuite/dist/styles/fonts/rsuite-icon-font.ttf)
      format("truetype"),
    url(https://cdn.jsdelivr.net/npm/rsuite/dist/styles/fonts/rsuite-icon-font.woff)
      format("woff");
  font-weight: 400;
  font-style: normal;
}

/*! CSS Used from: Embedded */
*,
:after,
:before {
  box-sizing: border-box;
}
a {
  background-color: transparent;
}
a:active,
a:hover {
  outline: 0;
}
button {
  color: inherit;
  font: inherit;
  margin: 0;
  overflow: visible;
  border-radius: 0;
  text-transform: none;
  -webkit-appearance: button;
  cursor: pointer;
  border-width: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button::-moz-focus-inner {
  border: 0;
  padding: 0;
}
[tabindex="-1"] {
  outline: none;
}

a {
  color: #1675e0;
  text-decoration: none;
}
a:focus,
a:hover {
  color: #0a5dc2;
}
a:active,
a:focus,
a:hover {
  text-decoration: underline;
}
a:active {
  color: #004299;
}
a:focus {
  outline: 0;
}

/* main calendar */
.rs-calendar {
  /*   background-color: none; */
  padding-top: 12px;

  position: relative;

  max-height: 250px;
  overflow: hidden;
  color: rgb(182, 182, 182);
}
.rs-calendar-table-cell-in-range,
.rs-calendar-table-cell-selected {
  position: relative;
}
.rs-calendar-table-cell-in-range:before,
.rs-calendar-table-cell-selected:before {
  content: "";
  display: block;
  width: 100%;
  margin-top: 3px;
  height: 24px;
  position: absolute;
  z-index: 0;
  top: 0;
}
.rs-calendar-table-cell-in-range .rs-calendar-table-cell-content,
.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
  z-index: 1;
  position: relative;
}
.rs-calendar-table-cell-in-range:before {
  background: rgba(255, 196, 0, 0.09);
  background: linear-gradient(
    0deg,
    rgba(255, 196, 0, 0.09) 0%,
    rgba(255, 196, 0, 0.2) 100%
  );
}
.rs-calendar-header {
  width: 280px;
  padding-left: 12px;
  padding-right: 12px;
}
.rs-calendar-header:after,
.rs-calendar-header:before {
  content: " ";
  display: table;
}
.rs-calendar-header:after {
  clear: both;
}
.rs-calendar-header-month-toolbar {
  display: inline-block;
}
.rs-calendar-header-month-toolbar {
  float: left;
}
.rs-calendar-header-backward,
.rs-calendar-header-forward,
.rs-calendar-header-title {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 900;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  outline: 0 !important;
  white-space: nowrap;
  border: none;
  -webkit-user-select: none;
  user-select: none;
  transition: color 0.2s linear, background-color 0.3s linear;
  color: #cccccc;
  background-color: transparent;
  font-size: 12px;
  line-height: 1.66666667;
  border-radius: 6px;
  padding: 2px;
  font-size: 14px;
  line-height: 1.42857143;
}
.rs-calendar-header-backward:focus,
.rs-calendar-header-backward:hover,
.rs-calendar-header-forward:focus,
.rs-calendar-header-forward:hover,
.rs-calendar-header-title:focus,
.rs-calendar-header-title:hover {
  color: #ffffff;
  text-decoration: none;
}
.rs-calendar-header-backward:active,
.rs-calendar-header-forward:active,
.rs-calendar-header-title:active {
  outline: 0;
  background-image: none;
}
/* Selected Month header title */
.rs-calendar-header-backward:focus,
.rs-calendar-header-backward:hover,
.rs-calendar-header-forward:focus,
.rs-calendar-header-forward:hover,
.rs-calendar-header-title:focus,
.rs-calendar-header-title:hover {
  color: rgb(255, 255, 255);
  background: #f7f7fa18;
  padding: 3px 5px 3px 5px;
}
.rs-calendar-header-backward:active,
.rs-calendar-header-backward:active:focus,
.rs-calendar-header-backward:active:hover,
.rs-calendar-header-forward:active,
.rs-calendar-header-forward:active:focus,
.rs-calendar-header-forward:active:hover,
.rs-calendar-header-title:active,
.rs-calendar-header-title:active:focus,
.rs-calendar-header-title:active:hover {
  color: rgb(255, 255, 255);
  background-color: #b2b1f0;
}
.rs-calendar-header-backward:focus,
.rs-calendar-header-backward:hover,
.rs-calendar-header-forward:focus,
.rs-calendar-header-forward:hover,
.rs-calendar-header-title:focus,
.rs-calendar-header-title:hover {
  color: #c0c0c0;
}
.rs-calendar-header-backward:active,
.rs-calendar-header-forward:active,
.rs-calendar-header-title:active {
  color: #272c36;
  background-color: #eae8e5;
  background-image: none;
}
.rs-calendar-header-backward:active:focus,
.rs-calendar-header-backward:active:hover,
.rs-calendar-header-forward:active:focus,
.rs-calendar-header-forward:active:hover,
.rs-calendar-header-title:active:focus,
.rs-calendar-header-title:active:hover {
  color: #272c36;
  background-color: #eae7e5;
}
.rs-calendar-header-title {
  margin: 0;
}
.rs-calendar-header-backward,
.rs-calendar-header-forward {
  font-size: 0;
  width: 24px;
}
.rs-calendar-header-backward:before,
.rs-calendar-header-forward:before {
  font-family: rsuite-icon-font;
  font-style: normal;
  font-size: 14px;
}
.rs-calendar-header-backward:before {
  content: "\ea0a";
}
.rs-calendar-header-forward:before {
  content: "\ea0c";
}
.rs-calendar-header-has-month:not(.rs-calendar-header-has-time) {
  margin: 0 auto;
  padding-left: 12px;
  padding-right: 12px;
}
.rs-calendar-header-has-month:not(.rs-calendar-header-has-time)
  .rs-calendar-header-month-toolbar {
  display: block;
  text-align: center;
  width: 100%;
}
.rs-calendar-header-has-month:not(.rs-calendar-header-has-time)
  .rs-calendar-header-month-toolbar:after,
.rs-calendar-header-has-month:not(.rs-calendar-header-has-time)
  .rs-calendar-header-month-toolbar:before {
  content: " ";
  display: table;
}
.rs-calendar-header-has-month:not(.rs-calendar-header-has-time)
  .rs-calendar-header-month-toolbar:after {
  clear: both;
}
.rs-calendar-header-has-month:not(.rs-calendar-header-has-time)
  .rs-calendar-header-backward {
  float: left;
}
.rs-calendar-header-has-month:not(.rs-calendar-header-has-time)
  .rs-calendar-header-forward {
  float: right;
}
.rs-calendar-view {
  padding: 4px 12px 12px;
}
.rs-calendar-table {
  display: table;
  table-layout: fixed;
  width: 100%;
}
.rs-calendar-table-row {
  display: table-row;
}
.rs-calendar-table-cell {
  display: table-cell;
  width: 1%;
  padding: 1px;
  text-align: center;
  vertical-align: middle;
}
/* last month */
.rs-calendar-table-cell-un-same-month .rs-calendar-table-cell-content,
.rs-calendar-table-cell-un-same-month .rs-calendar-table-cell-content:hover {
  color: #324e6b;
}

/* date circles */
.rs-calendar-table-cell-content {
  display: inline-block;
  font-size: 14px;
  line-height: 1.42857143;
  padding: 5px;
  cursor: pointer;
  border-radius: 6px;
}
.rs-calendar-table-cell-content:hover {
  background-color: #f2faff;
}
/* current Date Outline */
.rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
  border: 2px solid #d99f01;
  box-shadow: 0 0 4px 0 #ffaa00;
  padding: 3px;
}

/* selected Dates */
.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
  color: rgb(255, 255, 255);
  font-weight: 600;

  background-color: #0744b6;
}

/* week day colors */
.rs-calendar-table-header-row .rs-calendar-table-cell-content {
  color: #aa7d39;
  font-size: 12px;
  line-height: 1.66666667;
  padding-top: 2px;
  padding-bottom: 2px;
}
.rs-calendar-table-header-row .rs-calendar-table-cell-content,
.rs-calendar-table-header-row .rs-calendar-table-cell-content:focus,
.rs-calendar-table-header-row .rs-calendar-table-cell-content:hover,
.rs-calendar-table-header-row .rs-calendar-table-cell-content:hover:focus {
  background: none;
  cursor: auto;
}
.rs-calendar-month-dropdown {
  position: absolute;
  top: 40px;
  border-top: 1px solid #4b4b4b;
  margin-top: 4px;
  width: 100%;
  max-width: 300px;
  background: rgb(1, 20, 53);
  background: linear-gradient(0deg, rgb(2, 21, 53) 0%, rgb(0, 47, 128) 100%);
  overflow: hidden;
  z-index: 1;
}
.rs-calendar-month-dropdown-scroll {
  height: 230px;
}
.rs-picker-toolbar {
  padding: 12px;

  /*   border-top: 1px solid #e5e5ea; */
}
.rs-picker-toolbar:after,
.rs-picker-toolbar:before {
  content: " ";
  display: table;
}
.rs-picker-toolbar:after {
  clear: both;
}
.rs-picker-toolbar-ranges {
  display: inline-block;
  margin-left: -10px;
  margin-top: 5px;
}
.rs-picker-toolbar-right {
  float: right;
}
.rs-picker-toolbar-option {
  display: none;
  /*   margin-bottom: 0;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  outline: 0 !important;
  white-space: nowrap;
  border: none;
  -webkit-user-select: none;
  user-select: none;
  font-size: 14px;
  line-height: 1.42857143;
  border-radius: 6px;
  color: #085cc9;
  font-weight: 400;
  border-radius: 0;
  padding: 0 10px; */
}
.rs-picker-toolbar-option:focus,
.rs-picker-toolbar-option:hover {
  color: #575757;
  text-decoration: none;
}
.rs-picker-toolbar-option:active {
  outline: 0;
  background-image: none;
}
.rs-picker-toolbar-option,
.rs-picker-toolbar-option:active {
  background-color: transparent;
}
.rs-picker-toolbar-option,
.rs-picker-toolbar-option:active,
.rs-picker-toolbar-option:focus,
.rs-picker-toolbar-option:hover {
  border-color: transparent;
}
.rs-picker-toolbar-option:focus,
.rs-picker-toolbar-option:hover {
  color: #024bb3;
  text-decoration: underline;
  background-color: transparent;
}
.rs-picker-toolbar-option:active,
.rs-picker-toolbar-option:active:focus,
.rs-picker-toolbar-option:active:hover {
  color: #003b94;
}
.rs-picker-toolbar-option:not(:last-child) {
  position: relative;
}
.rs-picker-toolbar-option:not(:last-child):before {
  content: "";
  width: 1px;
  height: 20px;
  background-color: #eae9e5;
  position: absolute;
  top: 0;
  right: 0;
}

/* OK button */
.rs-picker-toolbar-right-btn-ok {
  background-image: radial-gradient(closest side at 50% 128%, #ffe8a4 5%, #bf7900 100%);
  border: 1px solid #d99f01;
  box-shadow: 0 0 5px 0 #ffaa00;
  border-radius: 30px;
  width: 60%;
  margin: auto;
  position: absolute;
  left: 10%;
  right: 10%;
  font-weight: 900;
  font-size: 16px;
  color: #040455;
  letter-spacing: 0.47px;
  text-align: center;
  text-shadow: 1px 1px 1px #ffffff;
}
.rs-picker-toolbar-right-btn-ok:focus,
.rs-picker-toolbar-right-btn-ok:hover {
  color: #575757;
  text-decoration: none;
}
.rs-picker-toolbar-right-btn-ok:active {
  outline: 0;
  background-image: none;
}
.rs-picker-toolbar-right-btn-ok:focus {
  color: #fff;
  background-color: #2589f5;
}
.rs-picker-toolbar-right-btn-ok:hover {
  color: #fff;
}
.rs-picker-toolbar-right-btn-ok:not(.rs-btn-disabled):hover {
  background-color: #2589f5;
}
.rs-picker-toolbar-right-btn-ok:active {
  color: #fff;
  background-color: #1675e0;
}
.rs-picker-toolbar-right-btn-ok:active:focus,
.rs-picker-toolbar-right-btn-ok:active:hover {
  color: #fff;
  background-color: #1675e0;
}
.rs-picker-toolbar-right-btn-ok:active {
  background-image: none;
}

.rs-picker-daterange-menu .rs-calendar:first-child {
  border-right: 1px solid #e6eae5;
}
.rs-picker-daterange-menu .rs-calendar-header {
  width: 100%;
  text-align: center;
}
.rs-picker-daterange-menu .rs-calendar-header-month-toolbar {
  float: none;
}
.rs-picker-daterange-menu .rs-calendar-month-dropdown {
  z-index: 1;
}
.rs-picker-daterange-menu .rs-picker-toolbar {
  margin-top: 4px;
}
.rs-picker-daterange-menu
  .rs-picker-daterange-panel-show-one-calendar
  .rs-picker-toolbar {
  max-width: 255px;
}
.rs-picker-daterange-menu .rs-picker-daterange-calendar-single .rs-calendar {
  border: 0;
  display: block;
  margin: auto;
}
.rs-picker-daterange-header {
  display: none;
  padding: 8px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  border-bottom: 1px solid #dddddd;
}
.rs-picker-menu .rs-picker-toolbar {
  /*   max-width: 280px; */
}
.rs-picker-menu .rs-calendar {
  /*   width: 280px; */
  display: block;
  margin: 0 auto;
}
.rs-picker-menu .rs-calendar .rs-calendar-table-cell-content {
  width: 30px;
  height: 30px;
}
.rs-picker-menu
  .rs-calendar
  .rs-calendar-table-header-row
  .rs-calendar-table-cell-content {
  height: 24px;
  padding-top: 0;
}
.rs-picker-menu .rs-calendar .rs-calendar-table-cell-content {
  padding-left: 0;
  padding-right: 0;
  display: inline-block;
}
.rs-picker-menu .rs-calendar .rs-calendar-month-dropdown-scroll {
  /*  height: 227px; */
}
/* main background color */
.rs-picker-menu {
  position: absolute;
  text-align: left;
  z-index: 7;
  border-radius: 6px;
  background: rgb(1, 20, 53);
  background: linear-gradient(0deg, rgb(2, 11, 27) 0%, rgb(0, 29, 80) 100%);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.06), 0 4px 4px rgba(0, 0, 0, 0.12);
  overflow: hidden;
  transition: none;
  margin-top: 7px;
}
.fade {
  opacity: 0;
  transition: opacity 0.15s linear;
}
.fade.in {
  opacity: 1;
}

/*! CSS Used from: Embedded */
*,
:after,
:before {
  box-sizing: border-box;
}
[tabindex="-1"] {
  outline: none;
}
.show {
  display: block !important;
}
.rs-calendar-month-dropdown-scroll div:focus {
  outline: none;
}

/* calendar month dropdown */
.rs-calendar-month-dropdown {
  display: none;
  /*   position: absolute;
  top: 40px;
  border-top: 1px solid #e5e5ea;
  margin-top: 4px;
  width: 100%;
  background-color: #fff;
  overflow: hidden;
  z-index: 1;  */
}
.rs-calendar-show-month-dropdown .rs-calendar-month-dropdown {
  display: block;
}
.rs-calendar-show-month-dropdown .rs-calendar-month-dropdown-content {
  animation: slideDown 0.3s linear;
}
.rs-calendar-month-dropdown-scroll {
  height: 230px;
}
.rs-calendar-month-dropdown-row {
  position: relative;
  padding: 5px 12px 5px 52px;
}
.rs-calendar-month-dropdown-row:not(:last-child) {
  border-bottom: 1px dotted #0000ff;
}
.rs-calendar-month-dropdown-year {
  position: absolute;
  top: calc(50% - 0.5em);
  left: 12px;
}
.rs-calendar-month-dropdown-year-active {
  color: #1675e0;
}
.rs-calendar-month-dropdown-list {
  display: block;
}
.rs-calendar-month-dropdown-list:after,
.rs-calendar-month-dropdown-list:before {
  content: " ";
  display: table;
}
.rs-calendar-month-dropdown-list:after {
  clear: both;
}
.rs-calendar-month-dropdown-cell {
  display: inline-block;
  float: left;
  width: calc((100% - 12px) / 6);
  margin: 1px;
  text-align: center;
  vertical-align: middle;
  width: calc((100% - 13px) / 6) \9;
}
@media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
  .rs-calendar-month-dropdown-cell {
    width: calc((100% - 13px) / 6);
  }
}
.rs-calendar-month-dropdown-cell-content {
  display: inline-block;
  font-size: 14px;
  line-height: 1.42857143;
  padding: 5px;
  cursor: pointer;
  border-radius: 6px;
}
.rs-calendar-month-dropdown-cell-content:hover {
  background-color: #f2faff;
}
.rs-calendar-month-dropdown-cell-active
  .rs-calendar-month-dropdown-cell-content {
  color: #fff;
  border-color: #3498ff;
  background-color: #3498ff;
}
.rs-picker-daterange-menu .rs-calendar-month-dropdown {
  z-index: 1;
}
.rs-picker-daterange-menu .rs-calendar-month-dropdown-list {
  width: 185px;
}
.rs-picker-menu .rs-calendar .rs-calendar-month-dropdown-cell-content {
  width: 30px;
  height: 30px;
}
.rs-picker-menu .rs-calendar .rs-calendar-month-dropdown-scroll {
  height: 227px;
}
.rs-picker-menu .ReactVirtualized__Grid.ReactVirtualized__List:focus {
  outline: none;
}
/*! CSS Used from: Embedded */
.resize-triggers {
  animation: 1ms resizeanim;
  visibility: hidden;
  opacity: 0;
}
.resize-triggers,
.resize-triggers > div,
.contract-trigger:before {
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  z-index: -1;
}
.resize-triggers > div {
  background: #eee;
  overflow: auto;
}
.contract-trigger:before {
  width: 200%;
  height: 200%;
}

/*! CSS Used keyframes */
@keyframes slideDown {
  0% {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}
